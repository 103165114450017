<template>
  <div>
    <Head :title="type==1?$t('weekRanking.head1'):$t('weekRanking.head2')"></Head>
    <div class="center" style="padding: 0 20px;" :style="'width:'+$setWidth">
      <div class="LP_title" style="padding-bottom:0;">{{$t('weekRanking.title1')}}</div>
      <!-- 等级 -->
      <div class="LP_box1 flex_lr">
        <div class="flex_row">
          <img src="@/image/yes.png" class="LP_icon" alt="">
          <div class="LP_text flex_row">
            <div>{{$t('weekRanking.text1')}}V{{level}}</div>
            <img :src="require('@/image/vip/V' + level+'.png')" style="width:20px;height:20px;margin-left:10px;" alt="">
          </div>
        </div>
      </div>
      <div class="LP_box1 flex_lr">
        <div class="flex_row">
          <img src="@/image/yes.png" v-if="upLevel.length>0&&upLevel[level].team<=info.powerTeam" class="LP_icon" alt="">
          <img src="@/image/no.png" v-else class="LP_icon" alt="">
          <div class="LP_text flex_row">{{$t('weekRanking.text2')}}{{info.powerTeam}}</div>
        </div>
      </div>
      <div class="LP_box1 flex_lr" v-if="pledge_arr[type]!=undefined">
        <div class="flex_row">
          <img src="@/image/yes.png" v-if="pledge_arr[type][0]<=user.userPledgeMt" class="LP_icon" alt="">
          <img src="@/image/no.png" v-else class="LP_icon" alt="">
          <div class="LP_text flex_row">质押MT {{user.userPledgeMt}}</div>
        </div>
         <div class="flex_row">
          <img src="@/image/yes.png" v-if="pledge_arr[type][1]<=user.userPledgeUlm" class="LP_icon" alt="">
          <img src="@/image/no.png" v-else class="LP_icon" alt="">
          <div class="LP_text flex_row">质押ULME{{user.userPledgeUlm}}</div>
        </div>
      </div>
      <div class="LP_title" style="padding-bottom:0;">{{$t('weekRanking.title2')}}</div>
      <!--  -->
      <div class="LP_box1">
        <div class="LP_box2" style="font-size:0.8rem;">
          <div class="LP_text">{{$t('weekRanking.text3')}}</div>
          <!-- <div class="LP_text">1.18 MT/25300.00ULME</div> -->
        </div>
        <div class="LP_box2" style="font-size:0.8rem;margin-top:10px;">
          <div class="LP_text">{{$t('weekRanking.text4')}}</div>
          <!-- <div class="LP_text">3.18MT/85300.00ULME</div> -->
        </div>

        <div class="LP_btn3" v-if="pledge_arr[type]!=undefined&&(pledge_arr[type][0]>user.userPledgeMt||pledge_arr[type][1]>user.userPledgeUlm)" style="margin-top:10px;" 
        @click="toPage('/My_Farmer')">质押代币不足,请前去质押</div>
        <div class="LP_btn3" v-else style="margin-top:10px;" @click="take">{{$t('weekRanking.text5')}}{{fromWei(balance[0])}} MT/{{fromWei(balance[1])}}ULME</div>
      </div>
      <!-- 表 -->
      <div class="LP_title" style="font-size:1rem;">{{type==1?$t('weekRanking.title3'):$t('weekRanking.title4')}}</div>
      <el-table :data="tableData" :header-cell-style="{background:'#0C2379',color:'#ffffff'}" style="width: 100%;overflow:auto;margin-top:10px;" cell-class-name="success-row" row-class-name="table_row" >
        <el-table-column type="index" align="center" width="50" :label="$t('weekRanking.text6')" />
        <el-table-column prop="address" align="center" :label="$t('weekRanking.text8')">
          <template slot-scope="scope">
            {{ scope.row.address.slice(0,3)+'*********'+scope.row.address.slice(scope.row.address.length-1) }}
          </template>
        </el-table-column>
        <el-table-column prop="count" align="center" width="80" :label="$t('weekRanking.text9')"/>
      </el-table>
    </div>
    <div style="height: 20px;"></div>
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';
export default {
  data () {
    return {
      type:1,
      info:{},
      user:{},
      balance:[],
      level:0,
      Loading:0,
      addPop2:false,
      tableData:[],
      last_page:1,
      current_page:1,
      is_next:false,

      pledge_arr:{
        2:[5,25000]
      }
    }
  },
  computed: {
    upLevel(){
      return this.$store.state.user.levelconfig
    }
  },
  async mounted () {
    await this.$onLaunched;
    this.type = this.$route.query.type
    if(this.upLevel.length==0){
      walletHelper.getLevelconfig()
    }
    this.init()
    this.getList()
  },
  created() {
    window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  methods: {
    // getList(){
    //   if(this.is_next)return
    //   this.is_next = true
    //   this.get('', {field:this.nType,page:this.current_page}, 'POST').then(res => {
    //     console.log(res.list.data)
    //     this.is_next = false
    //     this.last_page = res.list.last_page;//分页总数
    //     this.current_page = res.list.current_page;//当前页
    //     if (this.current_page==1) {
    //       this.tableData = res.list.data
    //     }else {
    //       if(res.list.data.length>0){
    //         this.tableData = this.tableData.concat(res.list.data)
    //       }
    //     }
    //   })
    // },
    init() {
      const that=this
      // walletHelper.getContract('rel').methods.getPressureNftTokenId(walletHelper.getAddress()).call().then(res=>{
      //   console.log(res)
      //   that.$set(that.info,'pressureNftTokenId',res>0?res:'无')
      //   if(that.info.pressureNftTokenId>0){
      //     walletHelper.getContract('daoV1').methods.getBranchCount(walletHelper.getAddress()).call().then((result)=>{
      //       that.$set(that.info,'mt10000',walletHelper.Wei(result[0]))
      //       that.$set(that.info,'ulm10000',walletHelper.Wei(result[1]))
      //       if(result[2]>0||result[3]>0){
      //         that.$set(that.info,'mtV',walletHelper.Wei(result[2]))
      //         that.$set(that.info,'ulmV',walletHelper.Wei(result[3]))
      //       }
      //     })
      //   }
      // })


      walletHelper.getContract('cc'+(that.type==1?'99':'88')).methods.getCount(walletHelper.getAddress()).call().then(res=>{
        console.log(res)
        that.balance=res
      })
      walletHelper.getUserData(walletHelper.getAddress(),(res,e)=>{
        if(res){
          that.user=res
          // if(Number(res.parentReward)>0){
          //   walletHelper.getContract('query').methods.getUsdtToToken(walletHelper.getContractAddress('mt'),res.parentReward).call().then(res=>{
          //     that.$set(that.user,'parentReward',walletHelper.Wei(res))
          //   })
          // }
            if(Number(res.power)>0||res._minerCount_390>0){
              walletHelper.getContract('daoV1h').methods.getTeamPower(walletHelper.getAddress()).call().then(res=>{
                console.log(res)
                that.$set(that.info,'powerTeam',res)

              })
            }
            that.level=res.userLevel

          }else{
            console.log('getUserData错误  error',e)
          
        }
      })

      walletHelper.getContract('daoV1').methods.getChildMintCount(walletHelper.getAddress()).call().then((result)=>{
        that.$set(that.info,'childCount',result)
      })


    },
    toPage(url){
      this.$router.push(url)
    },
    take(){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      if(that.balance.length==0||(that.balance[0]==0&&that.balance[1]==0)){
        that.$toast('没有可领取的')
        return
      }

      this.sendWeb3(walletHelper.getContract('cc'+(that.type==1?'99':'88')).methods.takeCount()).then((result)=>{
        console.log(result)
        that.init()
        that.closeLoading('提取成功')
        that.addPop=false
      })
    },
    getList(){
      const that=this
      walletHelper.getContract('cc'+(that.type==1?'99':'88')).methods.getUserList().call().then((result)=>{
        console.log(result)
        let n=0
        let data=[]

        for(let i=1;i<result.length;i++){
            walletHelper.getContract('daoV1h').methods.getTeamPower(String(result[i])).call().then((e)=>{
              console.log(i,result[i],e)
              
              data.push({address:result[i],count:e})
              n++
              if(n>=result.length-1){
                for(let j=0;j<data.length;j++){
                  for(let k=j+1;k<data.length;k++){
                    if(Number(data[j].count)<Number(data[k].count)){
                      let obj=data[j]
                      data[j]=data[k]
                      data[k]=obj
                      
                    }
                  }
                }
                that.tableData=data
              }
            })
        }
        
      })

    },
    dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    fromWei(wei){
      return wei?walletHelper.Wei(wei):0
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.next_pages()
      }
    },
    next_pages(){
      if(this.tableData!=undefined&&this.tableData.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
  }
}
</script>
 
<style lang="scss">
// 图表
.el-table {
  font-family: simsong;
  color: #ffffff !important;
  background: #041123;
  font-size: 0.76rem !important;
}
.el-table__header-wrapper{
  border-radius: 5px !important;
  overflow: hidden;
}
.el-table__body tr:hover > td {
  background-color: transparent !important;
}
.el-table--border .el-table__cell {
  border: none;
}
.el-table tr:nth-of-type(1n+0) {
  background: #020b1b;
}
.el-table tr:nth-of-type(2n+0) {
  background: #081850;
}
.el-table td{
  border: none !important;
}
.el-table th{
  border-bottom: none !important;
}
.el-table::before{
  width: 0 !important;
}
</style>
<style lang="scss" scoped>
  .LP_icon{
    width: 15px;
    margin-right: 10px;
  }
  .LP_title{
    color: #00FFEA;
    font-size: 1.1rem;
    padding: 10px 0;
  }
  .LP_subtitle{
    font-size: 0.95rem;
    font-family: Microsoft YaHei;
    color: #00FFEA;
    padding: 10px 0;
    i{
      margin-left: 5px;
      font-size: 0.95rem;
    }
  }
  .LP_title2{
    color: #ffffff;
    font-size: 1rem;
    padding-bottom: 10px;
  }
  .LP_text{
    color: #ffffff;
    font-size: 0.8rem;
    padding: 8px 0;
  }
  .LP_box1{
    width: 100%;
    background: #0C2379;
    border-radius: 8px;
    padding: 10px 13px;
    margin: 15px 0;
  }
  .LP_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 5px 13px;
    color: #ffffff;
  }
  .LP_btn3{
    width: 100%;
    background: #00FFEA;
    border-radius: 8px;
    padding: 8px;
    text-align: center;
  }
  .LP_btn{
    text-align: center;
    border-radius: 5px;
    color: #05faeb;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 3px 18px;
    border: 1px solid #05faeb;
    white-space: nowrap;
  }
  .LP_btn2{
    text-align: center;
    background: #05faeb;
    border-radius: 5px;
    color: #000;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 5px 8px;
    white-space: nowrap;
  }
</style>